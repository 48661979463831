@mixin for-phone {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin for-big-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

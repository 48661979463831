.header {
  position: fixed;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: var(--header-height);
  width: 100%;
  background: #000000;
  border-bottom: solid 1px var(--border-color-light);
  z-index: 2;

  &__logo {
    background: no-repeat center/200px url("../../assets/images/header/sw_logo_horizontal.png");
    width: 200px;
  }
}

.fork {
  width: 60px;
  border-left: solid 1px var(--border-color-light);
  display: flex;
  justify-content: center;
  align-items: center;

  &__github-icon {
    display: block;
    width: 30px;
    height: 30px;
    background: no-repeat center/ cover url("../../assets/images/footer/github-logo.svg");
  }
}

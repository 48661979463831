.burger-menu {
  box-sizing: border-box;
  width: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: solid 1px var(--border-color-light);
  cursor: pointer;

  &__bar {
    border: solid 2px var(--border-color-light);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  height: 100vh;
  transition: opacity .5s ease-in-out;

  .side-menu-opened & {
    display: flex;
    opacity: 1;
  }

  &__menu {
    overflow-y: scroll;
    background-color: #000000;
    width: var(--sidemenu-width);
    height: 100vh;
  }

  &__overlay {
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 0.5;
    cursor: pointer;
  }

  .sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .side-menu-items {
    padding: 50px 0 0 25px;

    &__item {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      margin-top: 10px;
    }

    &__link {
      color: var(--main-color);
      text-decoration: none;

      &--active {
        text-decoration: underline;
      }
    }
  }
}
